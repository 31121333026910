<template>
  <el-container>
    <el-header>
      <Navigation />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import Navigation from '@/components/Navigation'
export default {
  components: { Navigation },
  data() {
    return {}
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.el-container {
  background: #fff;
}

.el-header {
  z-index: 1;
  padding: 0;
}

.el-main {
  background: #f2f4f6;
}
</style>
